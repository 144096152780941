/** @define footer */

:root {
  --footer-property: value;
}

.ds-footer__wrapper {
  position: relative;
  background-color: var(--clr-dark);
  margin-top: var(--d-grid-unit--p);
}

.ds-footer__wrapper:after {
  content: '';
  display: block;
  position: absolute;
  top: -48px;
  width: 100%;
  height: 112px;
  /*background: linear-gradient(-85deg, #3DCD92, #E9D430);  */
  background-image: url(../../images/wavey-border-bottom.svg);
  background-position: center bottom;
  background-size: auto 100%;
  z-index: 0;
}

.ds-footer {
  background-color: var(--clr-dark);
  max-width: var(--doc-width);
  margin: auto;
}

.ds-footer__padder {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 auto 0 auto;
  padding: 0 var(--grid-unit) var(--d-grid-unit--p) var(--grid-unit);
}

.ds-footer__section {
  flex: 1;
  min-width: var(--doc-min-width);
  min-height: 10px;
}

.ds-footer__section--social {
  flex: 0;
  min-width: unset;
}

.ds-footer__section--incidentals {
  flex: 1 0 100%;
  min-width: unset;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ds-footer__nav {
}

.ds-footer__navlist {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
  overflow: visible;
  margin: 0;
  padding: 0;
}

.ds-footer__navitem {
  font-family: var(--display-font);
  display: inline-block;
  font-size: var(--font-size-1);
  padding: var(--grid-unit-h--p) var(--grid-unit--p) var(--grid-unit-h--p) 0;

  &:before {
    content: unset;
  }
}

.ds-footer__navlist--right .ds-footer__navitem {
  padding: var(--grid-unit-h--p) 0 var(--grid-unit--p) var(--grid-unit-h--p);
}

.ds-footer__navitem--logo {
}

.ds-footer__navlink {
  display: inline-block;
  color: var(--clr-dark-shade-light-6);
  border-radius: var(--grid-unit-e);
}

.ds-footer__navlink--icon {
  line-height: 50%;
  padding: var(--grid-unit-q);
}

.ds-footer__navicon {
  width: var(--d-grid-unit--p);
  height: var(--d-grid-unit--p);
  fill: var(--clr-dark-shade-light-6);
}

.ds-footer__textlink {
  color: var(--clr-darkish);
}

.ds-footer__textlink:hover {
  color: var(--clr-tertiary);
  background-color: rgba($clr-light, 0.1);
}

.ds-footer__navlink:hover,
.ds-footer__navlink:active,
.ds-footer__textlink:hover,
.ds-footer__textlink:active {
  color: var(--clr-tertiary);
  background-color: rgba($clr-light, 0.1);
}

.ds-footer__navlink:focus,
.ds-footer__textlink:focus {
  outline-color: rgba($clr-tertiary, 0.7);
}

.ds-footer__navlink:focus:active,
.ds-footer__textlink:focus:active {
  outline-color: rgba($clr-tertiary, 0.7);
}

.ds-footer__navlink--icon:hover .ds-footer__navicon,
.ds-footer__navlink--icon:active .ds-footer__navicon,
.ds-footer__navlink--icon:focus .ds-footer__navicon {
  fill: var(--clr-tertiary);
}

.ds-footer__text {
  flex: 1;
  font-size: var(--font-size--1);
  color: var(--clr-dark-shade-light-6);
  padding: var(--grid-unit--p) 0;
  margin: 0;
}

.ds-footer__text--impactsummary {
  flex: 1 0 100%;
  padding-right: var(--grid-unit);
}

/* .ds-footer__carbonbadge {
  flex: 1 0 100%;
  text-align: left !important;
}

.ds-footer__carbonbadge > div {
  display: inline-block;
  margin-right: var(--grid-unit--p);
}

.ds-footer__carbonbadge > p {
  display: inline-block !important;
  font-family: Lato, sans-serif !important;
  font-size: 1.2rem !important;
  color: var(--clr-dark-shade-light-6) !important;
}
#wcb_2 {
  color: #00ffbc !important;
} */

.ds-footer__text:last-child {
  padding-top: 0;
}

.ds-footer__ecologi {
  flex: 0;
  display: inline-block;
  padding: var(--grid-unit-h);
}

.ds-footer__cb {
  flex: 0;
  display: inline-block;
  padding: var(--grid-unit-h);
}

.ds-footer__ecologi-image {
  width: 140px;
}

.ds-footer__onepc {
  flex: 0;
  display: inline-block;
  align-self: center;
  margin-right: var(--grid-unit-h);
  padding: var(--grid-unit-h);
}

.ds-footer__onepc-image {
  display: block;
  width: 152px;
  height: 64px;
  text-indent: -9000px;
  background-image: url(../../images/onepc.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 800px) {
  .ds-footer__text--impactsummary {
    flex: 1;
  }
}
