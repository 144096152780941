/** @define doc */

:root {
  --doc-property: value;
}

.ds-doc {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden; /* Hides Dodecahedrons */
  /* Prevent weirdness when ultra zoomed out */
  max-width: 3968px;
  box-shadow: 0 0 64px var(--clr-greyish-shade-dark-5);
  margin: auto;
  background-color: var(--clr-greyish);
  background-image: linear-gradient(0deg, var(--clr-greyish), var(--clr-light));
}

.ds-doc__header {
  flex: 0 0 auto;
  width: 100%;
  /*background-color: var(--clr-dark);*/
  z-index: 2;
}

.ds-doc__page {
  position: relative;
  flex: 1 0 auto;
  width: 100%;
  max-width: var(--doc-width);
  margin: auto;
  /* padding-bottom: 20em; */
  z-index: 0;
}

.ds-doc__page--full-width {
  max-width: 100%;
}

.ds-doc__page:before, .ds-doc__page:after {
  content: '';
  position: absolute;
  z-index: 0;
  background-position: center center;
  background-size: contain;
  transition: all 200ms ease-in;
}

.ds-doc__page:before {
  display: none;
  left: -50%;
  top: 0;
  width: 512px;
  height: 512px;
  background-image: url(../../images/dodecahedron-full-yellow.png);
  background-repeat: no-repeat;
  transform: translateY(-15%);
}

.ds-doc__page--full-width:before, .ds-doc__page--full-width:after {
  content: unset;
  display: none;
}

.ds-doc__page:after {
  display: block;
  right: -10%;
  bottom: 0;
  width: 320px;
  height: 320px;
  background-image: url(../../images/dodecahedron-full-turquoise.png);
  background-repeat: no-repeat;
  transform: translateY(50%);
}

.ds-doc__footer {
  flex: 0 0 auto;
  width: 100%;
  /*background-color: var(--clr-dark);*/
  z-index: 1;
}

.ds-doc--homepage {
  .ds-doc__page:before {
    left: auto;
    right: -55%;
    top: 0;
    /* 591 x 898 */
    width: 591px;
    height: 898px;
    background-image: url(../../images/dodecahedron-full-group-linked.png);
  }

  .ds-doc__page:after {
    right: auto;
    left: -10%;
    bottom: 0;
    width: 512px;
    height: 512px;
  }
}


.ds-doc__background {
  position: relative;
  width: 100%;
  height: 256px;
  background-color: white;
  background-repeat: repeat-x;
  background-position: center center;

  mask-image: url(../../images/wavey-border-bottom-mask.svg);
  /* mask-repeat: space; */
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center bottom;
}

/* 16 x 6 ratio in Craft */
.ds-doc__backgroundimg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}


@media (min-width: 800px) {
  .ds-doc__page {
    /* padding-bottom: 16em; */
    &:after {
      width: 400px;
      height: 400px;
    }
  }
}

@media (min-width: 1048px) {
  .ds-doc__page:before {
    display: block;
  }

  .ds-doc--homepage .ds-doc__page:after {
    left: -20%;
  }
}

@media (min-width: 1200px) {
  .ds-doc__page:after {
    width: 512px;
    height: 512px;
  }
  .ds-doc__background {
    height: 512px;
  }
}

@media (min-width: 1300px) {
  .ds-doc--homepage .ds-doc__page:after {
    left: -40%;
  }
}
