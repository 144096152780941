/** @define screens */

:root {
  /* --screens-pack-offset: calc(var(--grid-unit) * 3); */
}

.ds-screens__grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(1fr);
  grid-column-gap: var(--grid-unit);
  grid-row-gap: var(--grid-unit);
}

.ds-screens__cell {
  display: flex;
  align-items: center;
  justify-content: center;
}


.ds-screens__cell--clamped {
  position: relative;
  height: 0;
  padding-top: 100%;
}

.ds-screens__cell--clamped .ds-image__picture { /* stylelint-disable-line */
  display: block;
  width: 100%;
  height: 100%;
}

.ds-screens__clamp {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.ds-screens__clamp .ds-lightbox__link { /* stylelint-disable-line */
  width: 100%;
  height: 100%;
}

.ds-screens__cell .ds-image__picture { /* stylelint-disable-line */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
}

.ds-screens__image {
  width: 100%;
  flex: 1;
  box-shadow: 0 var(--grid-unit-h) var(--grid-unit) hsla(0, 0%, 5%, 0.10);
}

.ds-screens__image.ds-image--portrait { /* stylelint-disable-line */
  height: 100%;
  width: auto;
}

.ds-screens__image.ds-image--landscape { /* stylelint-disable-line */
  width: 100%;
}



@media (min-width: 640px) {

  .ds-screens__grid {
    grid-template-columns: 1fr 1fr;
  }

  .ds-screens__grid--1 {
    grid-template-columns: 1fr;
  }

  .ds-screens__grid--2 {
    grid-template-columns: 1fr 1fr;
  }

}

@media (min-width: 960px) {

  .ds-screens__grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .ds-screens__grid--1 {
    grid-template-columns: 1fr;
  }

  .ds-screens__grid--2 {
    grid-template-columns: 1fr 1fr;
  }

}