/** @define tag */

:root {
}

.ds-tag__group {
  display: inline-block;
  padding: var(--grid-unit-h) 0;
}

.ds-tag {
  display: inline-block;
  text-transform: uppercase;
  font-family: var(--body-font);
  font-size: var(--font-size--2);
  font-weight: var(--body-font-bold-weight);
  color: var(--clr-light);
  letter-spacing: 0.5em;
}

.ds-tag--dark {
  color: var(--clr-dark);
}