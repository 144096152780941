/** @define device */

:root {
  --device-pack-offset: calc(var(--grid-unit) * 3);
}

.ds-device__pack {
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding-bottom: var(--device-pack-offset);
}

.ds-device__layout {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* align-items: center; */
  grid-row: 1;
  grid-column: 1;
}

.ds-device__layout--desktop {
}

.ds-device__layout--mobile {
  justify-content: flex-end;
  z-index: 1;
  margin-bottom: calc(-1 * var(--device-pack-offset) );
}

.ds-device {
  
}

.ds-device--desktop {
  width: 84%;
}

.ds-device--mobile {
  width: 28%;
  position: relative;
}

/* Background */
.ds-device--mobile:after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 336 708'%3E%3Crect width='336' height='708' fill='%23041220' fill-rule='evenodd' rx='31'/%3E%3C/svg%3E");
  background-size: 99% 99%;
  background-position: center center;
  background-repeat: no-repeat;
}

.ds-device__browser {
  position: relative;
  height: 0;
  width: 100%;
  background-size: 100% auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 var(--grid-unit) var(--grid-unit) hsla(0, 0%, 5%, 0.10);
  overflow: visible;
}

.ds-device__browser--desktop {
  /* ratio */ 
  /* 944 * 740 */
  padding-top: calc((740/944) * 100%);
  background-image: url(../../images/device-ui-desktop.svg);
  border-radius: calc((4 / 944) * 100%) / calc((4 / 740) * 100%);
}

.ds-device__browser--mobile {
  /* ratio */ 
  /* 336*708 */
  padding-top: calc((708/336) * 100%);
  background-image: url(../../images/device-ui-mobile.svg);
  border-radius: calc((92 / 976) * 100%) / calc((92 / 1962) * 100%);
}

.ds-device__title {
  top: 0;
  color: black;
  position: absolute;
  bottom: 0;
}

.ds-device__viewport {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  line-height: 0;
}

.ds-device__browser--desktop .ds-device__viewport {
  width: 100%;
  bottom: 0px;
  border-radius: 0% 0% calc((4 / 944) * 100%) calc((4 / 944) * 100%) / 0% 0% calc((4 / 740) * 100%) calc((4 / 740) * 100%);
}

.ds-device__browser--mobile .ds-device__viewport {
  /* top: calc((56 / 708) * 100%); */
  bottom: calc((8 / 708) * 100%);
  left: calc((8 / 336) * 100%);
  width: calc((320 / 336) * 100%);
  /* 320 * 692  */
  height: calc((644 / 708) * 100%);
  border-radius: 0% 0% calc((76 / 976) * 100%) calc((76 / 976) * 100%) / 0% 0% calc((76 / 1962) * 100%) calc((76 / 1962) * 100%);
}

.ds-device__screenshot {
  width: 100%;
}