/* typography */

/* @link https://utopia.fyi/generator-mk-ii?c=320,18,1.2,1280,21,1.2,5,2, */

/* stylelint-disable */
:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1280;

  --fluid-screen: 100vw;
  --fluid-bp: calc((var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) / (var(--fluid-max-width) - var(--fluid-min-width)));
}

@media screen and (min-width: 1280px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

:root {
  --f--2-min: 12.5;
  --f--2-max: 14.58;
  --step--2: calc(((var(--f--2-min) / 16) * 1rem) + (var(--f--2-max) - var(--f--2-min)) * var(--fluid-bp));

  --f--1-min: 15;
  --f--1-max: 17.5;
  --step--1: calc(((var(--f--1-min) / 16) * 1rem) + (var(--f--1-max) - var(--f--1-min)) * var(--fluid-bp));

  --f-0-min: 18;
  --f-0-max: 21;
  --step-0: calc(((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) * var(--fluid-bp));

  --f-1-min: 21.6;
  --f-1-max: 25.2;
  --step-1: calc(((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) * var(--fluid-bp));

  --f-2-min: 25.92;
  --f-2-max: 30.24;
  --step-2: calc(((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) * var(--fluid-bp));

  --f-3-min: 31.1;
  --f-3-max: 36.29;
  --step-3: calc(((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) * var(--fluid-bp));

  --f-4-min: 37.32;
  --f-4-max: 43.55;
  --step-4: calc(((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) * var(--fluid-bp));

  --f-5-min: 44.79;
  --f-5-max: 52.25;
  --step-5: calc(((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) * var(--fluid-bp));

  --font-size--2: var(--step--2);
  --font-size--1: var(--step--1);
  --font-size-0: var(--step-0);
  --font-size-1: var(--step-1);
  --font-size-2: var(--step-2);
  --font-size-3: var(--step-3);
  --font-size-4: var(--step-4);
  --font-size-5: var(--step-5);

  --display-font: 'Calistoga', sans-serif;
  /*--body-font: 'Raleway', sans-serif;*/
  /*--body-font: 'Source Sans', sans-serif;*/
  --body-font: 'Lato', sans-serif;
  --body-font-size: var(--font-size-0);
  --body-font-smaller-size: 1.6rem;
  --body-font-normal-weight: 300;
  --body-font-bold-weight: 700;
}

/* stylelint-enable */
