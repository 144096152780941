/** @define figure */

:root {
  --figure-width: 300px;
}

.ds-figure {
  width: 100%;
  height: auto;
  line-height: 0;
  border-radius: var(--grid-unit-e);
  /* overflow: hidden; prevents focus being visible */
  text-align: center;
  /*background-color: var(--clr-greyish-shade-dark-4);*/
}

.ds-figure + hr { /* stylelint-disable-line */
  padding-top: calc(var(--d-grid-unit) - var(--grid-unit-h));
}

.ds-figure__wrapper {
  background-color: rgba(#B7C4D2, 0.5);
}

.ds-figure--transparent .ds-figure__wrapper {
  background-color: transparent;
}

.ds-figure--visible-overflow {
  overflow: visible;
}

.ds-figure--portrait.ds-figure--center .ds-figure__wrapper { /* stylelint-disable-line */
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: visible;
  position: relative;
}

.ds-figure--left, .ds-figure--right {
  width: 50%;
  max-width: var(--figure-width);
}

.ds-figure--left {
  float: left;
  margin: 0 var(--grid-unit--p) var(--grid-unit--p) 0;
}

.ds-figure--right {
  float: right;  
  margin: 0 0 var(--grid-unit--p) var(--grid-unit--p);
}

.ds-figure--center {
  margin-bottom: var(--grid-unit);
}

.ds-figure__img {
  width: 100%;
  height: auto;
  margin: auto;
  line-height: 0;
  box-shadow: 0 0 32px var(--clr-greyish-shade-dark-1);
}

.ds-figure--portrait.ds-figure--center .ds-figure__img { /* stylelint-disable-line */
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ds-figure__caption {
  position: relative;
  z-index: 1;
  background-color: rgba(#CAD7E5, 0.5);
  padding: var(--grid-unit-h) var(--grid-unit);
}

.ds-figure--transparent .ds-figure__caption {
  background-color: transparent;
  padding: var(--grid-unit-h) 0;
}

.ds-figure__caption--inherit * {  /* stylelint-disable-line */
  color: inherit;
}

.ds-figure--video .ds-figure__wrapper { /* stylelint-disable-line */
  padding-bottom: 56.17978%;
  height: 0;
  position: relative;
}

.ds-figure__video, .ds-figure--video iframe { /* stylelint-disable-line */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}


@media (min-width: 640px) { 
  .ds-figure--left, .ds-figure--right {
    width: 100%;
  }
}