/** @define lightbox */

.ds-lightbox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--grid-unit);
  background-color: hsla(var(--clr-greyish-shade-dark-1-hsl), 0.9);
}

.ds-lightbox--closed {
  display: none;
}

/* .ds-lightbox__layout { */
  /* position: absolute; */
  /* overflow: auto; */
  /* height: auto; */
  /* max-height: 100%; */
  /* transform: translate(-50%, -50%); */
  /* top: 50%; */
  /* left: 50%; */
/* } */

.ds-lightbox__total {
  position: absolute;
  display: inline-block;
  top: var(--grid-unit-h);
  left: var(--grid-unit-h);
  border-radius: var(--grid-unit-q);
  z-index: 2;
  color: var(--clr-light);
  padding: var(--grid-unit-q) var(--grid-unit-h); 
  background-color: var(--clr-dark);
}

.ds-lightbox__caption {
  position: absolute;
  display: block;
  text-align: center;
  border-radius: var(--grid-unit-q);
  bottom: 0;
  z-index: 2;
  color: var(--clr-light);
  padding: var(--grid-unit-q) var(--grid-unit); 
  background-color: var(--clr-dark);
}

.ds-lightbox__caption-button {
  margin-top: var(--grid-unit-h) !important;
}

.ds-lightbox__close {
  position: absolute;
  top: var(--grid-unit-h);
  right: var(--grid-unit-h);
  z-index: 10;
}

.ds-lightbox__close:active, .ds-lightbox__close:focus {
  position: absolute;
  top: var(--grid-unit-h);
}

.ds-lightbox__content {
  z-index: 1;
  display: flex;
  height: 100%;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s linear 0s;
}

.ds-lightbox__content:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s linear 0.1s;
}

.ds-lightbox__content--loading:after {
  opacity: 1;
  /* animation: coinspin 5s cubic-bezier(0, 0.2, 0.9, 1) infinite; */
  /* background-image: url('../images/logos/df-logo.svg'); */
  /* background-size: 64px 64px; */
  /* background-position: center center; */
  /* background-repeat: no-repeat; */
}

.ds-lightbox .ds-lightbox__picture,
.ds-lightbox .ds-lightbox__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}


.ds-lightbox__control {
  visibility: visible;
  position: absolute;
  z-index: 2;
  bottom: 0;
  margin-bottom: var(--grid-unit);
  /* bottom: calc(var(--grid-unit) + var(--grid-unit-e)); */
}

.ds-lightbox__control:active, .ds-lightbox__control:focus {
  position: absolute;
  top: auto;
}

.ds-lightbox__control--disabled {
  visibility: hidden;
}

.ds-lightbox__control--previous {
  left: var(--grid-unit-h);
}

.ds-lightbox__control--next {
  right: var(--grid-unit-h);
}

.ds-lightbox__svg {
  width: var(--grid-unit);
}

.ds-lightbox__svg path { /* stylelint-disable-line */
  fill: var(--clr-light);
}

.ds-lightbox__link {
  position: relative;
  display: inline-block;
  width: 100%;
}

.ds-lightbox__zoom {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(var(--grid-unit) * 1.8);
  height: calc(var(--grid-unit) * 1.8);
  transition: all 200ms ease-in;
}

.ds-lightbox__link:active .ds-lightbox__zoom, .ds-lightbox__link:focus .ds-lightbox__zoom, .ds-lightbox__link:hover .ds-lightbox__zoom { /* stylelint-disable-line */
  opacity: 1;
}

.ds-lightbox__zoom path { /* stylelint-disable-line */
  transition: all 200ms ease-in;
  transform: translate(100%, -100%);
  fill: var(--clr-dark);
}

.ds-lightbox__link:active .ds-lightbox__zoom path, .ds-lightbox__link:focus .ds-lightbox__zoom path, .ds-lightbox__link:hover .ds-lightbox__zoom path { /* stylelint-disable-line */
  transform: translate(0,0);
}

