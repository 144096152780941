/** @define aside */

:root {
  --aside-width: 300px;
}

.ds-aside {
  position: relative;
  float: right;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 var(--grid-unit) 0;
  padding: var(--grid-unit-h);
  border-radius: var(--grid-unit-e);
  border-top: var(--grid-unit-e) solid var(--clr-darkish);
  border-bottom: var(--grid-unit-e) solid var(--clr-darkish);
  background-color: rgba(#D0DDEB, 0.5);

  h1, h2, h3, h4 { /* stylelint-disable-line */
    color: rgba(#041220, 0.8);
    font-size: var(--font-size-0);
    line-height: 120%;
    margin: 0 0 var(--grid-unit-h) 0;
  }

}

.ds-aside p { /* stylelint-disable-line */ 
  font-size: var(--font-size--1);
  color: rgba(#041220, 0.8);
  margin: 0 0 var(--grid-unit-h) 0;
}

.ds-aside li { /* stylelint-disable-line */ 
  font-size: var(--font-size--1);
}

.ds-aside p:last-child { /* stylelint-disable-line */
  margin-bottom: var(--grid-unit-q);
}

@media (min-width: 448px) {
  .ds-aside {
    position: relative;
    float: right;
    width: 50%;
    max-width: var(--doc-aside-width);
    box-sizing: border-box;
    margin: var(--grid-unit) 0 var(--grid-unit) var(--grid-unit);
    padding: var(--grid-unit-h);
    border: 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: var(--grid-unit-e);
      background-color: var(--clr-greyish-shade-dark-4);
      border-radius: var(--grid-unit-e);
    }
  }
}


@media (min-width: 1580px) {

  .ds-aside {
    margin-right: -166px;
  }

}

.ds-aside--primary {
  background-color: rgba(#F41B3E, 0.08);
  h1, h2, h3, h4 { /* stylelint-disable-line */
    color: mix($clr-primary, $clr-dark, 25%);
  }
  &:after {
    background-color: var(--clr-primary-shade-light-1);
  }
}

.ds-aside--secondary {
  background-color: rgba(#FFE100, 0.08);
  h1, h2, h3, h4 { /* stylelint-disable-line */
    color: mix($clr-secondary, $clr-dark, 25%);
  }
  &:after {
    background-color: var(--clr-secondary-shade-light-1);
  }
}

.ds-aside--tertiary {
  background-color: rgba(#3DCD92, 0.08);
  h1, h2, h3, h4 { /* stylelint-disable-line */
    color: mix($clr-tertiary, $clr-dark, 25%);
  }
  &:after {
    background-color: var(--clr-tertiary-shade-light-1);
  }
}