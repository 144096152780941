/* @define-good */

:root {
  /* --good-property: value; */
}

.ds-good__title {
  color: var(--clr-ecogreen-shade-dark-9);
}

.ds-good__grid {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.ds-good__grid-item {
  flex: 1 0 auto;
  margin: 0;
  border-radius: var(--grid-unit-q);
  padding: var(--grid-unit);
  background-color: var(--clr-ecogreen-shade-light-6);
  margin-bottom: var(--grid-unit);
}

.ds-good__grid-item:last-child {
  margin-bottom: 0;
}

.ds-good__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.ds-good__item-logo {
  flex: 1 0 auto;
  max-width: 240px;
}

.ds-good__item-details {
  flex: 1;
}

@media (min-width: 960px) {
  .ds-good__item {
    flex-direction: row;
    align-items: flex-start;
  }
}
