/** @define message */

:root {
  --message-width: 300px;
}

.ds-message p { /* stylelint-disable-line */
  display: block;
  border-radius: var(--grid-unit-h--p);
  margin: var(--grid-unit--p) 0;
  padding: var(--grid-unit-h--p) var(--grid-unit--p);
  border: 1px solid var(--clr-greyish-shade-dark-2);
}

.ds-message--neutral p { /* stylelint-disable-line */
  background-color: rgba(#D0DDEB, 0.5);
}

.ds-message--alert p { /* stylelint-disable-line */
  color: mix($clr-primary, $clr-dark, 15%);
  border: 1px solid var(--clr-primary-shade-light-2);
  background-color: rgba($clr-primary, 0.25);
}

.ds-message--warning p { /* stylelint-disable-line */
  color: mix($clr-secondary, $clr-dark, 15%);
  border: 1px solid var(--clr-secondary-shade-dark-2);
  background-color: rgba($clr-secondary, 0.25);
}

.ds-message--success p { /* stylelint-disable-line */
  color: mix($clr-tertiary, $clr-dark, 15%);
  border: 1px solid var(--clr-tertiary-shade-light-2);
  background-color: rgba($clr-tertiary, 0.25);
}
