/** @define homepage */

:root {
  --homepage-property: value;
}

.ds-homepage {
  display: grid;
  grid: auto-flow / repeat(3, 1fr);
  grid-column-gap: var(--d-grid-unit--p);
}

.ds-homepage__section {
  padding: var(--grid-unit--p) 0;
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row: 1;
  text-align: center;

  &:nth-child(1) {
  }

  &:nth-child(2) {
    /*
    grid-column-start: 1;
    grid-column-end: span 3;
    */
    grid-row: 2;
  }

  &:nth-child(3) {
    /*
    grid-column-start: 1;
    grid-column-end: span 3;
    */
    grid-row: 3;
  }

  &:nth-child(4) {
    /*
    grid-column-start: 1;
    grid-column-end: span 3;
    */
    grid-row: 4;
  }
}




@media (min-width: 800px) {

  .ds-homepage {
    grid: auto-flow / repeat(6, 1fr);
  }

  .ds-homepage__section {
    padding: var(--d-grid-unit--p) 0;
    grid-column-start: 1;
    grid-column-end: span 6;

    &:nth-child(1) {
      grid-column-start: 2;
      grid-column-end: span 4;
    }


    /*    
    &:nth-child(2) {
      grid-column-start: 1;
      grid-column-end: span 6;
    }

    &:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: span 6;
    }

    &:nth-child(4) {
      grid-column-start: 3;
      grid-column-end: span 4;
    }
    */

  }
}