/** @define-disciplines */

:root {
  /* --disciplines-property: value; */
}

.ds-disciplines {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 0;
}

.ds-disciplines__item {
  font-size: 1.2rem;
  min-width: 14em;
  max-width: 16em;
}
