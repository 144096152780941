/** @define-stats */

:root {
  /* --stats-property: value; */
}

.ds-stats__grid {
  display: grid;
  grid-auto-flow: row dense;
  grid-row-gap: var(--grid-unit);
  grid-column-gap: var(--grid-unit);
  grid-template-columns: 1fr;
  grid-template-rows: repeat(1fr);
  grid-auto-rows: 1fr;
  padding: 0;
  margin: 0;
}

.ds-stats__grid-item {
  padding: 0;
  margin: 0;
}

.ds-stats__item {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex: 1 0 30%;
  border-radius: var(--grid-unit-q);
  color: var(--clr-light);
}

.ds-stats__item--green {
  background-color: var(--clr-ecogreen);
  /* background-color: var(--clr-tertiary); */
}

.ds-stats__item--red {
  background-color: var(--clr-primary);
}

.ds-stats__item--grey {
  background-color: var(--clr-greyish-shade-dark-9);
}

.ds-stat__figure {
  flex: 0 1 30%;
  font-family: var(--display-font);
  font-size: 200%;
  padding: var(--grid-unit-h) var(--grid-unit-h) var(--grid-unit-h) var(--grid-unit) ;
  text-align: center;
}

.ds-stat__description {
  flex: 1;
  font-size: 80%;
  line-height: 120%;
  font-weight: var(--body-font-bold-weight);
  text-transform: uppercase;
  text-align: left;
  padding: var(--grid-unit-h) var(--grid-unit) var(--grid-unit-h) var(--grid-unit-h) ;
}

.ds-stats__footnote {
  padding: var(--grid-unit) 0 0 0;
}

.ds-stats__footnote p {
  font-style: italic;
  font-size: var(--font-size--2);
  color: var(--clr-greyish-shade-dark-7);
}

.ds-stats__footnote a {
  color: var(--clr-greyish-shade-dark-7);
}

@media (min-width: 640px) {

  .ds-stats__grid {
    grid-template-columns: 1fr 1fr;
  }

}

@media (min-width: 960px) {

  .ds-stats__grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

}