/** @define-card */

:root {
  --card-property: value;
}

.ds-card__list {
  width: 100%;
  text-align: center;
}

.ds-card__content-area {
  text-align: left;
  margin: auto;
  max-width: var(--doc-width); 
}

.ds-card {
}

.ds-card__background {
  height: 80vh;
  width: 100%;
}

.ds-card__backgroundimage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ds-card__intro {
  max-width: 42em;
}

.ds-card__intro h1 {
  word-break: break-word;
}

.ds-card__intro p {
  margin: 0;
}

.ds-card__title {
  font-size: var(--font-size--1);
  color: var(--clr-greyish-shade-dark-8);
  letter-spacing: 0.2rem;
}

.ds-card--leader {
  color: var(--clr-light);
  text-shadow: 0 1px 2px hsla(0, 1%, 5%, 0.1);

  padding-bottom: 36px;
  margin-bottom: -36px;
  mask-image: url(../../images/wavey-border-bottom-mask.svg);
  /* mask-repeat: space; */
  mask-size: auto 4000px;
  mask-repeat: no-repeat;
  mask-position: right bottom;
}

.ds-card--leader-dark {
  color: var(--clr-dark);
  text-shadow: unset;
}

.ds-card--textslide {
  background-color: var(--clr-light);
}

.ds-card--textslide p:last-child { /* stylelint-disable-line */
  margin-bottom: 0;
}

.ds-card--deviceslide {
  background-color: var(--clr-light);
}

.ds-card--overview {
  background-color: var(--clr-greyish-shade-dark-1);
}

.ds-card--stats {
  z-index: 1;
  padding-top: 36px;
  background-color: var(--clr-greyish-shade-dark-1);
}

.ds-card--good {
  z-index: 1;
  padding-top: 36px;
  margin-bottom: calc(0 - (2 * var(--grid-unit)));
  padding-bottom: calc(4 * var(--grid-unit));
  background-color: var(--clr-ecogreen-shade-light-9);
}

.ds-card--visit {
  background-color: var(--clr-greyish-shade-dark-1);
}

.ds-card__image {
  width: 100%;
}

@media (min-width: 640px) {

  .ds-card__background {
    height: 65vh;
  }

}

@media (min-width: 960px) {

  .ds-card__background {
    height: 55vh;
  }

}