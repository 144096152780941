/** @define button */

:root {
  --button-width: 300px;
}



.ds-button {
  display: inline-block;
  font-family: var(--display-font);
  border-top-right-radius: var(--d-grid-unit);
  border-bottom-left-radius: var(--d-grid-unit);
  border-bottom-right-radius: var(--d-grid-unit);
  border-top-left-radius: var(--d-grid-unit);
  line-height: 100%;
  cursor: pointer;
  text-decoration: none;
  transition: all 200ms ease-in;
  border: 0;
  appearance: none;

  &:hover {
    text-decoration: underline;
  }

  &:active, &:focus {
    text-decoration: underline;
    position: relative;
    top: -1;
  }

}

.ds-button--round {
  padding: 0;
  border-radius: 50%;
}


.ds-button__padder {
  display: inline-block;
  padding: calc(1.5 * var(--grid-unit-q--p)) var(--grid-unit--p);
  line-height: 100%;
  vertical-align: text-top;
  text-decoration: inherit;
}

.ds-button--round .ds-button__padder {
  border-radius: 50%;
  position: relative;
  padding: calc(var(--grid-unit));
}

.ds-button__icon {
  position: absolute;
  transform: translate(-50%, -50%)
}


.ds-button--primary {
  font-size: var(--font-size-0);
  color: var(--clr-light);
  background-color: var(--clr-primary);

  &:hover {
    background-color: var(--clr-primary-shade-dark-4);
  }

  &:active, &:focus {
    background-color: var(--clr-primary-shade-dark-5);
  }

}


.ds-button--ecogreen {
  font-size: var(--font-size-0);
  color: var(--clr-light);
  /* color: var(--clr-ecogreen-shade-dark-11); */
  background-color: var(--clr-ecogreen-shade-dark-8);

  &:hover {
    background-color: var(--clr-ecogreen-shade-dark-9);
  }

  &:active, &:focus {
    background-color: var(--clr-ecogreen-shade-dark-10);
  }

}


.ds-button--light {
  font-size: var(--font-size-0);
  color: var(--clr-dark);
  background-color: var(--clr-light);

  &:hover {
    background-color: var(--clr-light);
  }

  &:active, &:focus {
    background-color: var(--clr-light);
  }

}


.ds-button--large {
  font-size: var(--font-size-1);
}