/** @define page */

:root {
  --page-property: value;
}

.ds-page {
}

/*.ds-page:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60%;
  height: 0;
  transform: translateY(50%);
  padding-top: 512px;
  max-width: 512px;
  max-height: 512px;
  background-image: url('../../images/dodecahedron-full-yellow.png');
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
}
*/

.ds-page__padder {
  position: relative;
  z-index: 2;
  padding: calc(var(--grid-unit) * 3) var(--grid-unit) calc(var(--grid-unit) * 3) var(--grid-unit);
}

.ds-page__padder--hasbackground {
  padding: var(--grid-unit) var(--grid-unit) calc(var(--grid-unit) * 3) var(--grid-unit);
}

.ds-card--slimline .ds-page__padder { /* stylelint-disable-line */
  padding: 0 var(--grid-unit) 0 var(--grid-unit);
}

.ds-page__gridsnap {
  padding: 0 calc(var(--grid-unit--p) * 3);
  /* /~ max-width: var(--doc-content-width); ~/ */
  /* margin: auto; */
/* } */
}

.ds-page__clamper {
  max-width: var(--doc-content-width);
  margin: auto;
}