/** @define header */

:root {
  --header-property: value;
}

.ds-header__wrapper {
  position: relative;
  width: 100%;
  height: calc(var(--grid-unit) * 5);
  background-color: var(--clr-dark);
}
.ds-header__wrapper:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -32px;
  width: 100%;
  height: 112px;
  /*background: linear-gradient(-85deg, #3DCD92, #E9D430);  */
  background-image: url(../../images/wavey-border.svg);
  background-position: center bottom;
  background-size: auto 100%;
  z-index: 0;
}

.ds-header {
  max-width: var(--doc-width);
  margin: auto;
  overflow: visible;
}

.ds-header__padder {
  text-align: right;
  position: relative;
  margin: var(--grid-unit) auto;
  padding: 0 var(--grid-unit);
  overflow: visible;
}

.ds-header__logo {
  display: block;
  position: absolute;
  z-index: 2;
  padding: 0;
  margin: 0;
  width: 9rem; /* 275 * 150 */
  height: 4.8rem; /* 1.83 ratio */
  background-image: url(../../images/dodeca-studio.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: visible;
}

.ds-header__logo--large {
  display: block;
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0 auto;
  width: 13.5rem; /* 275 * 150 */
  height: 7.2rem; /* 1.83 ratio */
  background-image: url(../../images/dodeca-studio.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: visible;
}

.ds-header__logo-link {
  display: block;
  width: 100%;
  height: 100%;
}

.ds-header__nav {
  display: none;
  width: 100%;

  position: fixed;
  z-index: 80;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--clr-dark);
}

.ds-header__nav-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 0;
  margin: 0;
  top: 0;
  right: 0;
  height: 64px;
  width: 64px;
  z-index: 100;
}

.ds-header__nav-checkbox {
  display: none;
  justify-content: unset;
}

.ds-header__nav-checkbox:checked ~ .ds-header__nav {
  display: block;
}

.ds-header__nav-toggle-icon {
  width: 64px;
  height: 64px;
}

.ds-header__nav-toggle-icon-trigram {
  stroke: var(--clr-light);
  transform: translate(calc((21 / 64) * 100%), calc((23.5 / 64) * 100%));
}

.ds-header__nav-toggle-icon-border {
  stroke: var(--clr-light);
}

.ds-header__nav-toggle-icon:focus .ds-header__nav-toggle-icon-border,
.ds-header__nav-toggle-icon:hover .ds-header__nav-toggle-icon-border {
  stroke: var(--clr-primary);
}

.ds-header__navlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: visible;
}

.ds-header__navitem {
  font-size: var(--font-size-5);
  font-family: var(--display-font);
  position: unset;
  margin: unset;

  &:before {
    content: unset;
  }
}

.ds-header__navlink {
  display: inline-block;
  padding: var(--grid-unit-h--p) var(--grid-unit--p);
  text-decoration: none;
  border-radius: var(--grid-unit-e--p);
}

.ds-header__navlink:hover,
.ds-header__navlink:active {
  text-decoration: underline;
  background-color: rgba($clr-light, 0.1);
}

.ds-header__navlink:focus {
  outline-color: rgba($clr-primary, 0.7);
}

.ds-header__navlink:focus:active {
  outline-color: rgba($clr-primary, 0.7);
}

@media (min-width: 640px) {
  .ds-header__nav-toggle {
    display: none;
  }

  .ds-header__nav {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    flex: 1 0 auto;
    flex-direction: column;
  }

  .ds-header__navlist {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    height: calc(var(--grid-unit) * 3);
    margin: 0;
    padding: 0 var(--grid-unit);
  }

  .ds-header__navitem {
    font-size: var(--font-size-2);
  }
}
