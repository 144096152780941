/** @define contentitem */

:root {
  
}

.ds-contentitem__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-auto-rows: minmax(16rem, auto);
  grid-column-gap: var(--d-grid-unit--p);
  grid-row-gap: var(--d-grid-unit--p);
  /* grid-auto-flow: dense; */
}

.ds-contentitem {
  display: block;
  border-radius: 4px;
  overflow: hidden;
  grid-column: span 1;
  /* grid-row: span 1; */
  position: relative;
  text-decoration: none;
}

.ds-contentitem--gridhelper {
  display: none;
  opacity: 0.5;
  background: linear-gradient(35deg, var(--clr-greyish-shade-dark-5) 0%, rgba(#3DCD92, 0) 40%);
  background-color: var(--clr-greyish-shade-dark-1);
}

.ds-contentitem__layout {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 100%;
}

.ds-contentitem--dsDark .ds-contentitem__layout { /* stylelint-disable-line */
  color: var(--clr-light);
  background-color: var(--clr-dark);
}

.ds-contentitem--hasimage.ds-contentitem--dsDark .ds-contentitem__layout { /* stylelint-disable-line */
  background: radial-gradient(ellipse at top left, rgba($clr-dark, 0.96), rgba($clr-dark, 0.2)),
    radial-gradient(ellipse at bottom left, rgba($clr-dark, 0.8), rgba($clr-dark, 0));
}

.ds-contentitem--dsGreen .ds-contentitem__layout { /* stylelint-disable-line */
  background-color: var(--clr-tertiary);
}

.ds-contentitem--hasimage.ds-contentitem--dsGreen .ds-contentitem__layout { /* stylelint-disable-line */
    background: radial-gradient(ellipse at top left, rgba($clr-tertiary, 0.96), rgba($clr-tertiary, 0)),
      radial-gradient(ellipse at bottom left, rgba($clr-tertiary, 1), rgba($clr-tertiary, 0));
}

.ds-contentitem--dsYellow .ds-contentitem__layout { /* stylelint-disable-line */
  background-color: var(--clr-secondary);
}

.ds-contentitem--hasimage.ds-contentitem--dsYellow .ds-contentitem__layout { /* stylelint-disable-line */
  background: radial-gradient(ellipse at top left, rgba($clr-secondary, 0.96), rgba($clr-secondary, 0.2)),
    radial-gradient(ellipse at bottom left, rgba($clr-secondary, 0.8), rgba($clr-secondary, 0));
}

.ds-contentitem--dsRed .ds-contentitem__layout { /* stylelint-disable-line */
  background-color: var(--clr-primary);
}

.ds-contentitem--hasimage.ds-contentitem--dsRed .ds-contentitem__layout { /* stylelint-disable-line */
  background: radial-gradient(ellipse at top left, rgba($clr-primary, 0.96), rgba($clr-primary, 0.2)),
    radial-gradient(ellipse at bottom left, rgba($clr-primary, 0.8), rgba($clr-primary, 0));
}

.ds-contentitem--dsLight .ds-contentitem__layout { /* stylelint-disable-line */
  background-color: var(--clr-light);
}

.ds-contentitem--hasimage.ds-contentitem--dsLight .ds-contentitem__layout { /* stylelint-disable-line */
  background: radial-gradient(ellipse at top left, rgba($clr-light, 0.96), rgba($clr-light, 0.2)),
    radial-gradient(ellipse at bottom left, rgba($clr-light, 0.8), rgba($clr-light, 0));
}

.ds-contentitem--dark .ds-contentitem__layout { /* stylelint-disable-line */
  color: var(--clr-dark);
}

.ds-contentitem__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: calc(var(--grid-unit--p) * 3) calc(var(--grid-unit--p) * 3) 0 calc(var(--grid-unit--p) * 3);
}

.ds-contentitem__controls {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 0;
  padding: 0 calc(var(--grid-unit--p) * 3) calc(var(--grid-unit--p) * 3) calc(var(--grid-unit--p) * 3);
}

.ds-contentitem__controls > .ds-button { /* stylelint-disable-line */
  flex: 0;
  align-self: flex-end;
  margin: 0 calc(var(--grid-unit--p) * -1) 0 0;
}

.ds-contentitem__category {
  display: block;
  font-size: var(--font-size--2);
  margin: 0 0 var(--grid-unit-h--p) 0;
  text-transform: uppercase;
  font-weight: var(--body-font-bold-weight);
  letter-spacing: 0.5rem;
  bottom: calc(var(--grid-unit--p) * 3);
  left: calc(var(--grid-unit--p) * 3);
}

.ds-contentitem--work .ds-contentitem__category {
  display: inline-block;
  flex: 0;
  /* align-self: flex-end; */
  margin: 0;
  margin-bottom: var(--grid-unit-h);
  color: var(--clr-light);
}

.ds-contentitem--dsDark .ds-contentitem__category {
  color: var(--clr-greyish-shade-dark-9);
}

.ds-contentitem--dsGreen .ds-contentitem__category {
  color: var(--clr-greyish-shade-dark-9);
}

.ds-contentitem--dsYellow .ds-contentitem__category {
  color: var(--clr-greyish-shade-dark-9);
}

.ds-contentitem--dsRed .ds-contentitem__category {
  color: var(--clr-greyish-shade-dark-9);
}

.ds-contentitem--dsLight .ds-contentitem__category {
  color: var(--clr-greyish-shade-dark-9);
}

.ds-contentitem--work .ds-contentitem__category { /* stylelint-disable-line */
  color: var(--clr-dark-shade-light-9);
}

.ds-contentitem--dark .ds-contentitem__category { /* stylelint-disable-line */
  color: var(--clr-dark-shade-light-1);
}

.ds-contentitem__title {
  font-size: var(--font-size-3);
  color: var(--clr-dark);
  word-break: break-word;
}

.ds-contentitem__sub-title {
  line-height: 160%;
  font-size: var(--font-size-0);
  font-family: var(--body-font);
  font-weight: var(--body-font-normal-weight);
  margin-bottom: var(--grid-unit-q);
}

.ds-contentitem--dsDark .ds-contentitem__title {
  color: var(--clr-light);
}

.ds-contentitem--dsGreen .ds-contentitem__title {
}

.ds-contentitem--dsYellow .ds-contentitem__title {
}

.ds-contentitem--dsRed .ds-contentitem__title {
}

.ds-contentitem--work .ds-contentitem__title {
  color: var(--clr-light);
}

.ds-contentitem--dark .ds-contentitem__title { /* stylelint-disable-line */
  color: var(--clr-dark);
}

.ds-contentitem--dsLight .ds-contentitem__title {
  color: var(--clr-dark);
}

.ds-contentitem--work .ds-contentitem__sub-title {
  color: var(--clr-light);
}

.ds-contentitem--dark .ds-contentitem__sub-title { /* stylelint-disable-line */
  color: var(--clr-dark);
}

.ds-contentitem__desc {
  display: none;
  color: var(--clr-dark);
  font-size: var(--font-size-0);
}

.ds-contentitem--dsDark .ds-contentitem__desc {
  color: var(--clr-greyish-shade-dark-2);
}

.ds-contentitem--dsGreen .ds-contentitem__desc {
}

.ds-contentitem--dsYellow .ds-contentitem__desc {
}

.ds-contentitem--dsRed .ds-contentitem__desc {
}

.ds-contentitem--dsLight .ds-contentitem__desc {
  color: var(--clr-dark);
}

.ds-contentitem--work .ds-contentitem__desc {
  color: var(--clr-light);
}

.ds-contentitem--dark .ds-contentitem__desc { /* stylelint-disable-line */
  color: var(--clr-dark);
}

.ds-contentitem__picture {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 600ms ease-in-out;
}

.ds-contentitem__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ds-contentitem:hover {
  text-decoration: none;
}

.ds-contentitem:hover .ds-contentitem__title {
  text-decoration: underline;
}

.ds-contentitem:focus .ds-contentitem__picture, .ds-contentitem:hover .ds-contentitem__picture {
  transform: scale(1.05);
}

.ds-contentitem:focus .ds-contentitem__button, .ds-contentitem:hover .ds-contentitem__button {
  background-color: var(--clr-primary-shade-dark-5);
}

.ds-contentitem--work:focus .ds-contentitem__button, .ds-contentitem--work:hover .ds-contentitem__button {
  background-color: var(--clr-light);
}


@media (min-width: 28rem) { 

  .ds-contentitem__list {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-auto-rows: minmax(20rem, auto);
  }

}


@media (min-width: 45.69rem) { 

  .ds-contentitem--gridhelper {
    /* display: ; */
  }

  .ds-contentitem--gridhelper-2col { /* stylelint-disable-line */
    display: block;
  }

  .ds-contentitem--article, .ds-contentitem--large { /* stylelint-disable-line */
    grid-column: span 2;
  }

  .ds-contentitem--article .ds-contentitem__content, .ds-contentitem--large .ds-contentitem__content { /* stylelint-disable-line */
    width: 80%;
  }

  .ds-contentitem__desc {
    display: block;
  }

}



@media (min-width: 67.31348rem) { 

  .ds-contentitem--gridhelper-2col { /* stylelint-disable-line */
    display: none;
  }

  .ds-contentitem--gridhelper-3col { /* stylelint-disable-line */
    display: block;
  }
  
}